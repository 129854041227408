import { defineNuxtRouteMiddleware } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import type { RouteLocationNormalized } from 'vue-router';

import { PRODUCT_FEAT_NAME } from '@/constants/products.const';
import { useAppStore } from '@/stores/app.store';
import useProductStore from '@/stores/product.store';
import { useUserStore } from '@/stores/user.store';
import type { SelectedProductHistory } from '@/types/selected-products-history/selected-products-history-model.type';

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const { meta, matched, params } = to;
  const { params: fromParams } = from;
  const productStore = useProductStore();
  const { productDetailLabel, product, productName, releaseStatusApiCalled } = storeToRefs(productStore);
  const { userInfo } = useUserStore();
  const {
    addProductToSelectedProductListHistory,
    isSelectedProductHistoryOnTop,
    fetchSelectedProductListHistory,
    isAlreadyFetchSelectedProductListHistory
  } = useAppStore();
  const { groupId, projectId, productId, buildId, discountId } = params;
  const { projectId: fromProjectId, productId: fromProductId } = fromParams;
  const memberNo = userInfo?.memberNo;
  let productNo: number = 0;
  if (!isNaN(Number(productId))) {
    productNo = Number(productId);
  }
  if (isAlreadyFetchSelectedProductListHistory === false && groupId) {
    await fetchSelectedProductListHistory(groupId as string);
  }
  if (memberNo && productNo > 0 && !isSelectedProductHistoryOnTop(productNo)) {
    const lnbUserProductInfo: SelectedProductHistory = {
      productName: productName.value,
      productNo,
      projectId: projectId as string,
      groupId: groupId as string,
      productType: product.value?.productType || '',
      productDetailType: product.value?.productDetailType || '',
      productDetailLabel: productDetailLabel.value
    };
    addProductToSelectedProductListHistory(lnbUserProductInfo);
  }

  const showProductKey = meta.showProductKey as boolean;
  const showProductSearch = meta.showProductSearch as boolean;
  const showFeatMenu = meta.showFeatMenu as boolean;
  const productFeat = meta.productFeat as string;

  const pageTitles: { title: string; url: string }[] = [];
  for (const m of matched) {
    const { meta: matchedMeta } = m;
    const pageTitle = matchedMeta.pageTitle as string;
    if (pageTitle) {
      let url = m.path
        .replace(/:groupId\(\)/, groupId.toString())
        .replace(/:projectId\(\)/, projectId.toString())
        .replace(/:productId\(\)/, productId.toString());

      if (buildId) {
        url = url.replace(/:buildId\(\)/, buildId.toString());
      }
      if (discountId) {
        url = url.replace(/:discountId\(\)/, discountId.toString());
      }
      pageTitles.push({
        title: pageTitle,
        url
      });
    }
  }

  if (fromProjectId !== projectId || fromProductId !== productId) {
    releaseStatusApiCalled.value = false;
  }

  await productStore.fetchProductReleaseStatus();

  // Because the middleware run before navigating to a route, we need to wait a bit to set the sticky value for the header
  // Otherwise, a UI issue will be occured
  setTimeout(() => {
    productStore.setIsSticky(productFeat === PRODUCT_FEAT_NAME.HOME);
    productStore.setPageTitles(pageTitles);
    productStore.setShowProductKey(showProductKey === true);
    productStore.setShowProductSearch(showProductSearch === true);
    productStore.setShowFeatMenu(showFeatMenu === undefined);
  }, 300);
});
